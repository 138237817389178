<template>
  <div id="gjs"></div>
</template>

<script>
import grapesjs from 'grapesjs'
import 'grapesjs/dist/css/grapes.min.css'
import 'grapesjs/dist/grapes.min.js'
import gjsBlockBasic from "grapesjs-blocks-basic";
// import grapesjsPresetWebpage from "grapesjs-preset-webpage";
import grapesjsBlocksBootstrap4 from 'grapesjs-blocks-bootstrap4';
export default {
  name: 'gjs',
  props: ['item'],
  mounted(){
    let editor = grapesjs.init({
      container: '#gjs',
      components: this.item.body,
      height: '900px',
      width: '100%',
      plugins: [gjsBlockBasic],
      // plugins: [grapesjsBlocksBootstrap4,grapesjsPresetWebpage,gjsBlockBasic],
      storageManager: false,
      // storageManager: {
      //   id: 'gjs-',
      //   type: 'local',
      //   contentTypeJson: true,
      //   autoload: true,
      //   autosave: true,
      //   storeComponents: true,
      //   storeStyles: true,
      //   storeHtml: true,
      //   storeCss: true,          'https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css',
      // },
      deviceManager: {
        devices:
            [
              {
                id: 'desktop',
                name: 'Desktop',
                width: '',
              },
              {
                id: 'tablet',
                name: 'Tablet',
                width: '768px',
                widthMedia: '992px',
              },
              {
                id: 'mobilePortrait',
                name: 'Mobile portrait',
                width: '320px',
                widthMedia: '575px',
              },
            ]
      },
      pluginsOpts: {
        grapesjsPresetWebpage: {
          blocksBasicOpts: {
            blocks: ['column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'image', 'video'],
            flexGrid: 1,
          },
          blocks: ['link-block', 'quote', 'text-basic'],
        },
      },
      canvas: {
        styles: [
          'https://msj.legal/public/assets/plugins/socicon/socicon.css',
          'https://msj.legal/public/assets/plugins/bootstrap-social/bootstrap-social.css',
          'https://msj.legal/public/assets/plugins/font-awesome/css/font-awesome.min.css',
          'https://msj.legal/public/assets/plugins/simple-line-icons/simple-line-icons.min.css',
          'https://msj.legal/public/assets/plugins/bootstrap/css/bootstrap.min.css',
          'https://msj.legal/public/assets/plugins/revo-slider/css/settings.css',
          'https://msj.legal/public/assets/plugins/revo-slider/css/layers.css',
          'https://msj.legal/public/assets/plugins/revo-slider/css/navigation.css',
          'https://msj.legal/public/assets/plugins/cubeportfolio/css/cubeportfolio.min.css',
          'https://msj.legal/public/assets/plugins/owl-carousel/assets/owl.carousel.css',
          'https://msj.legal/public/assets/plugins/fancybox/jquery.fancybox.css',
          'https://msj.legal/public/assets/plugins/slider-for-bootstrap/css/slider.css',
          'https://msj.legal/public/assets/demos/default/css/plugins.css',
          'https://msj.legal/public/assets/demos/default/css/components.css',
          'https://msj.legal/public/assets/demos/default/css/themes/default.css',
          'https://msj.legal/public/assets/demos/default/css/custom.css'
        ],
        scripts: [
          'https://msj.legal/public/assets/plugins/jquery.min.js',
          'https://msj.legal/public/assets/plugins/jquery-migrate.min.js',
          'https://msj.legal/public/assets/plugins/bootstrap/js/bootstrap.min.js',
          'https://msj.legal/public/assets/plugins/jquery.easing.min.js',
          'https://msj.legal/public/assets/plugins/reveal-animate/wow.js',
          'https://msj.legal/public/assets/demos/default/js/scripts/reveal-animate/reveal-animate.js',
          'https://msj.legal/public/assets/plugins/revo-slider/js/jquery.themepunch.tools.min.js',
          'https://msj.legal/public/assets/plugins/revo-slider/js/jquery.themepunch.revolution.min.js',
          'https://msj.legal/public/assets/plugins/revo-slider/js/extensions/revolution.extension.slideanims.min.js',
          'https://msj.legal/public/assets/plugins/revo-slider/js/extensions/revolution.extension.layeranimation.min.js',
          'https://msj.legal/public/assets/plugins/revo-slider/js/extensions/revolution.extension.navigation.min.js',
          'https://msj.legal/public/assets/plugins/revo-slider/js/extensions/revolution.extension.video.min.js',
          'https://msj.legal/public/assets/plugins/revo-slider/js/extensions/revolution.extension.parallax.min.js',
          'https://msj.legal/public/assets/plugins/cubeportfolio/js/jquery.cubeportfolio.min.js',
          'https://msj.legal/public/assets/plugins/owl-carousel/owl.carousel.min.js',
          'https://msj.legal/public/assets/plugins/counterup/jquery.waypoints.min.js',
          'https://msj.legal/public/assets/plugins/counterup/jquery.counterup.min.js',
          'https://msj.legal/public/assets/plugins/fancybox/jquery.fancybox.pack.js',
          'https://msj.legal/public/assets/plugins/smooth-scroll/jquery.smooth-scroll.js',
          'https://msj.legal/public/assets/plugins/typed/typed.min.js',
          'https://msj.legal/public/assets/plugins/slider-for-bootstrap/js/bootstrap-slider.js',
          'https://msj.legal/public/assets/plugins/js-cookie/js.cookie.js',
          'https://msj.legal/public/assets/base/js/components.js',
          'https://msj.legal/public/assets/base/js/components-shop.js',
          'https://msj.legal/public/assets/base/js/app.js',
          'https://msj.legal/public/assets/demos/default/js/scripts/revo-slider/slider-4.js'
        ],
      }
    })
    let that  = this;
    editor.on('update', function (){
      that.$emit('gjsUpdate', editor.getHtml())
    })
  }
}
</script>
<style>
</style>